import io, { Socket } from "socket.io-client";
import { urlSocket } from "../../constant/constants";
import { getAccessToken } from "../auth/tokens";

class SocketEmitter {
  clientSocket!: Socket;

  constructor() {}

  connect() {
    if (this.clientSocket) {
      this.clientSocket.close();
    }

    this.clientSocket = io(urlSocket+'?token='+getAccessToken(), {
      reconnection: true,
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: `Bearer ${getAccessToken()}`,
          },
        },
      },
    });
  }
}

export const socketEmitter = new SocketEmitter();
socketEmitter.connect();
