import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logging, register, setLoader } from "../../redux/actions/actions";
import { connect } from "react-redux";

import { RootState } from "../../redux/reducers/rootReducer";
import { useHistory } from "react-router";
import { Loggin, url, urlLocal } from "../../constant/constants";
import { getCookie, setCookie } from "../../helpers/cookies";
import { setAccessToken, setRefreshToken } from "../../api/auth/tokens";
import api from "../../api";

interface Registered {
  isFailed?: boolean;
  isLogging?: boolean;
}

const styleError = "#ff000078";
const fieldNames = {
  password: "Пароль",
  email: "Email",
  name: "Имя",
  promocode: "Промокод",
};

const Register: React.FC<Registered> = ({ isFailed, isLogging }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [promocode, setPromocode] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [check, setChek] = useState(false);
  const [checkData, setChekData] = useState(false);
  const [checkEmail, setChekEmail] = useState(false);

  // const [touched, setTouched] = useState(false);
  // const [nameTouched, setNameTouched] = useState(false);
  // const [emailTouched, setEmailTouched] = useState(false);
  // const [passwordTouched, setPasswordTouched] = useState(false);

  const [nameValidation, setNameValidation] = useState("");
  const [passwordValidation, setPasswordValidation] = useState("");
  const [emailValidation, setEmailValidation] = useState("");
  const [checkValidation, setCheckValidation] = useState(false);
  const [checkDataValidation, setCheckDataValidation] = useState(false);

  const [error, setError] = useState("");

  const history = useHistory();
  useEffect(() => {
    if (isLogging) {
      dispatch(setLoader(""));
      history.push(`/admin/dashboard`);
    }
  }, [isLogging]);

  const valid = () => {
    var isValid = true;
    isValid = validateName(name) && isValid;
    isValid = validateEmail(email) && isValid;
    isValid = validatePassword(password) && isValid;
    isValid = validateCheck(check) && isValid;
    isValid = validateCheckData(checkData) && isValid;
    return isValid;
  };

  function validateName(name: string) {
    if (!name.length) {
      setNameValidation("Введите имя");
      return false;
    }
    return true;
  }

  function validateEmail(email: string) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      setEmailValidation("Неправильный формат почты");
      return false;
    }
    return true;
  }

  function validatePassword(password: string) {
    // var re = /^(?=.*[A-Z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (password.length < 8) {
      setPasswordValidation("Пароль должен иметь минимум 8 символов");
      return false;
    }
    return true;
  }

  function validateCheck(check) {
    if (!check) {
      setCheckValidation(true);
      return false;
    }
    return true;
  }

  function validateCheckData(checkData) {
    if (!checkData) {
      setCheckDataValidation(true);
      return false;
    }
    return true;
  }

  const generateError = (param: string) => {
    switch (param) {
      case "email": {
        setError("Email Адрес занят");
        break;
      }
      case "password":
        setError("Пароль должен быть не менее 8-ми символов");
        break;
      default:
        setError("Неизвестная ошибка");
    }
  };

  const save = async () => {
    setError("");
    if (!valid() || !check || !checkData || !name || !email || !password) {
      return;
    }
    // const result = await fetch(`${url}/registration`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json;charset=UTF-8",
    //   },
    //   body: JSON.stringify({
    //     email,
    //     password,
    //     repeatPassword: password,
    //     checkEmail,
    //     name,
    //   }),
    // });
    try {
      const result = await api.comments.register({
        email,
        password,
        checkEmail,
        name,
        promocode,
      });
    } catch (error: any) {
      const body = error.response.data;
      if (body?.errors?.errors) {
        let errorsBackend = "";
        for (const err of body.errors.errors) {
          if (err.param in fieldNames) {
            errorsBackend += `${fieldNames[err.param]}: ${err.msg}\n`;
          }
        }
        setError(errorsBackend);
      }

      if (body?.message) {
        setError(body.message);
      }
      return;
    }

    const login = await api.comments.logging(email, password);

    setAccessToken(
      login.data.accessToken.token,
      login.data.accessToken.expiresIn
    );
    setRefreshToken(
      login.data.refreshToken.token,
      login.data.refreshToken.expiresIn
    );

    dispatch(logging.done({ params: login, result: login }));
    history.push(`/admin/dashboard`);
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Регистрация
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      {fieldNames.name}
                    </label>
                    <input
                      value={name}
                      style={{
                        border: `1px solid ${
                          !!nameValidation.length ? styleError : "transparent"
                        }`,
                      }}
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Name"
                      onChange={(event) => {
                        setNameValidation("");
                        setName(event.target.value);
                      }}
                    />
                  </div>
                  {!!nameValidation.length && (
                    <div>
                      <p
                        style={{
                          color: "red",
                          fontWeight: 200,
                          fontSize: 14,
                          paddingLeft: "1rem",
                        }}
                      >
                        {nameValidation}
                      </p>
                    </div>
                  )}
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      {fieldNames.email}
                    </label>
                    <input
                      style={{
                        border: `1px solid ${
                          !!emailValidation.length ? styleError : "transparent"
                        }`,
                      }}
                      value={email}
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      onChange={(event) => {
                        setEmailValidation("");
                        setEmail(event.target.value);
                      }}
                    />
                  </div>
                  {!!emailValidation.length && (
                    <div>
                      <p
                        style={{
                          color: "red",
                          fontWeight: 200,
                          fontSize: 14,
                          paddingLeft: "1rem",
                        }}
                      >
                        {emailValidation}
                      </p>
                    </div>
                  )}
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      {fieldNames.password}
                    </label>
                    <input
                      style={{
                        border: `1px solid ${
                          !!passwordValidation.length
                            ? styleError
                            : "transparent"
                        }`,
                      }}
                      onChange={(event) => {
                        setPasswordValidation("");
                        setPassword(event.target.value);
                      }}
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={password}
                    />
                  </div>
                  {!!passwordValidation.length && (
                    <div>
                      <p
                        style={{
                          color: "red",
                          fontWeight: 200,
                          fontSize: 14,
                          paddingLeft: "1rem",
                        }}
                      >
                        {passwordValidation}
                      </p>
                    </div>
                  )}
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      {fieldNames.promocode}
                    </label>
                    <input
                      style={{
                        border: `1px solid ${
                          !!passwordValidation.length
                            ? styleError
                            : "transparent"
                        }`,
                      }}
                      onChange={(event) => {
                        setPromocode(event.target.value.toUpperCase());
                      }}
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Promocode"
                      value={promocode}
                    />
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        style={{
                          border: `${
                            !!checkValidation ? "3px solid" + styleError : ""
                          }`,
                        }}
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        onChange={(event) => {
                          setCheckValidation(false);
                          setChek(event.target.checked);
                        }}
                        checked={check}
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Согласен(-а), ознакомлен(-а) и принимаю в полном объеме
                        условия{" "}
                        <a
                          href="/user_agreement"
                          className="text-lightBlue-500"
                          target="_blank"
                        >
                          пользовательского соглашения.
                        </a>
                      </span>
                    </label>
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        style={{
                          border: `${
                            !!checkDataValidation
                              ? "3px solid " + styleError
                              : ""
                          }`,
                        }}
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        onChange={(event) => {
                          setCheckDataValidation(false);
                          setChekData(event.target.checked);
                        }}
                        checked={checkData}
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Согласен(-а), на обработку персональных данных.{" "}
                      </span>
                    </label>
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        onChange={(event) => setChekEmail(event.target.checked)}
                        checked={checkEmail}
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Согласен(-а), на получения рекламной рассылки.{" "}
                      </span>
                    </label>
                  </div>
                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        // setTouched(true);
                        save();
                      }}
                    >
                      Создать пользователя
                    </button>
                  </div>

                  {!!error.length && (
                    <div>
                      <p
                        style={{
                          color: "red",
                          fontWeight: 200,
                          fontSize: 14,
                          paddingLeft: "1rem",
                        }}
                      >
                        {error}
                      </p>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: RootState) => {
  return {
    isFailed: state.Reducer.loaderName == "error",
    isLogging: state?.Reducer?.user?.email,
  };
};
export default connect(mapStateToProps)(Register);
