import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  logging,
  refreshPassword,
  register,
  sendKey,
  setLoader,
} from "../../redux/actions/actions";
import { connect } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { useHistory } from "react-router";
import { Loaders } from "../../constant/constants";

interface Registered {
  isFailed?: boolean;
  isLogging?: boolean;
  isSend?: boolean;
  isFalseRefresh?: boolean;
  isRefresh?: boolean;
}
const Register: React.FC<Registered> = ({
  isFailed,
  isLogging,
  isSend,
  isFalseRefresh,
  isRefresh,
}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [check, setChek] = useState(false);
  const [checkData, setChekData] = useState(false);
  const [checkEmail, setChekEmail] = useState(false);
  const [init, setInit] = useState(false);
  const styleError = "1px solid #ff000078";
  const history = useHistory();

  const clearError = useCallback(() => {
    setError(false);
    setErrorPassword(false);
    setRefresh(false);
  }, []);
  useEffect(() => {
    dispatch(setLoader(""));
  }, []);
  useEffect(() => {
    if (init) {
      // dispatch(logging.started({ email, password }));
      history.push(`/admin/dashboard`);
    }
  }, [isRefresh]);

  useEffect(() => {
    if (isLogging) {
      dispatch(setLoader(""));
      history.push(`/admin/dashboard`);
    }
  }, [isLogging]);

  useEffect(() => {
    if (isFalseRefresh) {
      setError(true);
    }
  }, [isFalseRefresh]);

  useEffect(() => {
    if (isSend) setChek(true);
  }, [isSend]);

  useEffect(() => {
    if (isLogging) {
      dispatch(setLoader(""));
      history.push(`/admin/dashboard`);
    }
  }, [isLogging]);
  const [text, setText] = useState("Отправить код подтверждения");
  const [errorEmail, setErrorEmail] = useState(false);
  const [error, setError] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (!check && isFailed) {
      setErrorEmail(true);
    }
  }, [isFailed]);

  const handle = () => {
    if (!check) {
      dispatch(sendKey.started({ email }));
      dispatch(setLoader(""));
      setInit(false);
      clearError();
    }

    if (check) {
      if (password == password2) {
        setRefresh(true);
        clearError();

        dispatch(refreshPassword.started({ email, password, key }));
      } else {
        setErrorPassword(true);
      }
    }
    // setTimeout(() => {
    //   // setErrorEmail(true)
    //   setChek(true);
    // }, 1000);
    //  setTimeout(()=>{
    //   setError(true)
    //  },1500)
    //  setTimeout(()=>{
    //   setErrorPassword(true)
    //  },2000)
  };

  function validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  const [key, setKey] = useState("");
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Восстановление пароля
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
                {errorEmail && (
                  <div>
                    <span
                      style={{
                        color: "red",
                        fontWeight: 200,
                        fontSize: 14,
                        paddingLeft: "1rem",
                      }}
                    >
                      Пользователь с данным email-адресом не зарегистрирован.
                    </span>
                  </div>
                )}
                {error && (
                  <div>
                    <span
                      style={{
                        color: "red",
                        fontWeight: 200,
                        fontSize: 14,
                        paddingLeft: "1rem",
                      }}
                    >
                      Вы ввели не верный код восстановления.
                    </span>
                  </div>
                )}
                {errorPassword && (
                  <div>
                    <span
                      style={{
                        color: "red",
                        fontWeight: 200,
                        fontSize: 14,
                        paddingLeft: "1rem",
                      }}
                    >
                      Введите одинаковый пароль в поле подтверждения пароля.
                    </span>
                  </div>
                )}
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      email
                    </label>
                    <input
                      disabled={check}
                      style={{
                        background: check ? "#ffffff69" : "",
                        border:
                          init && !email.length && validateEmail(email)
                            ? styleError
                            : "",
                      }}
                      onChange={(event) => {
                        setInit(true);
                        setEmail(event.target.value);
                      }}
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="email"
                      value={email}
                    />
                  </div>

                  {check && (
                    <>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Код восстановления пароля
                        </label>
                        <input
                          value={key}
                          style={{
                            border: init && !key.length ? styleError : "",
                          }}
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Код восстановления пароля"
                          onChange={(event) => {
                            setKey(event.target.value);
                          }}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Пароль
                        </label>
                        <input
                          value={password}
                          style={{
                            border: init && !password.length ? styleError : "",
                          }}
                          type="password"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Password"
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Подтвердите пароль
                        </label>
                        <input
                          style={{
                            border: init && !password2.length ? styleError : "",
                          }}
                          value={password2}
                          type="password"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Password"
                          onChange={(event) => {
                            setPassword2(event.target.value);
                          }}
                        />
                      </div>{" "}
                    </>
                  )}

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setInit(true);
                        handle();
                      }}
                    >
                      {text}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: RootState) => {
  return {
    isFailed: state.Reducer.loaderName == "error",
    isLogging: state?.Reducer?.user?.email,
    isSend: state.Reducer.loaderName == Loaders.sendMessage,
    isFalseRefresh: state.Reducer.loaderName == Loaders.falseRefresh,
    isRefresh: state.Reducer.loaderName == Loaders.refresh,
  };
};
export default connect(mapStateToProps)(Register);
